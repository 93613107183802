import React from 'react'
import * as sections from './sections'

function Flexible({ fields }) {
  if (!fields)
    return <div className="mt-5">Deze pagina heeft nog geen velden.</div>

  return fields.map((field, key) => {
    switch (field.internal.type) {
      case 'WordPressAcf_banner':
        return <sections.Banner {...field} key={key} />

      case 'WordPressAcf_column':
        return <sections.Column {...field} key={key} />

      case 'WordPressAcf_column_gold_background':
        return <sections.ColumnGoldBackground {...field} key={key} />

      case 'WordPressAcf_column_with_image_left':
        return <sections.ColumnWithImageLeft {...field} key={key} />

      case 'WordPressAcf_column_with_image_right':
        return <sections.ColumnWithImageRight {...field} key={key} />

      case 'WordPressAcf_video':
        return <sections.Video {...field} key={key} />
    }
  })
}

export default Flexible
