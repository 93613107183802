import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import Flexible from 'components/flexible'

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />
      {acf.flexible_page && acf.flexible_page.length > 0 ? (
        <Flexible fields={acf.flexible_page} />
      ) : (
        <section className="container mt-4">
          <h2>{title}</h2>
          <div>Deze pagina heeft nog geen content.</div>
        </section>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        flexible_page {
          ... on WordPressAcf_banner {
            id
            description
            description_under_banner
            link {
              title
              url
            }
            internal {
              type
            }
            image {
              localFile {
                publicURL
              }
            }
          }

          ... on WordPressAcf_column {
            editor
            id
            internal {
              type
            }
          }

          ... on WordPressAcf_column_gold_background {
            id
            title
            link {
              title
              url
            }
            description
            internal {
              type
            }
          }

          ... on WordPressAcf_column_with_image_left {
            id
            description
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            internal {
              type
            }
          }

          ... on WordPressAcf_column_with_image_right {
            id
            description
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            internal {
              type
            }
          }

          ... on WordPressAcf_video {
            id
            youtube_link
            internal {
              type
            }
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
