import React from 'react'
import parse from 'html-react-parser'
import styled from 'styled-components'

import ButtonInverse from '../../elements/ButtonInverse'
import ParseContent from '../../shared/ParseContent'

const Content = styled(ParseContent)`
  * {
    line-height: 2;
  }
`

function ColumnGoldBackground({ title, description, link }) {
  return (
    <section className="color-background-main mb-5 py-5">
      <div className="container py-lg-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-10 text-white">
            <h2 className="text-center font-family-secondary">
              {parse(title)}
            </h2>
            <Content className="py-4 text-justify" content={description} />
            {link && (
              <div className="d-flex justify-content-center">
                <ButtonInverse to={link.title}>{link.title}</ButtonInverse>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ColumnGoldBackground
