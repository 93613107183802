import React from 'react'
import queryString from 'query-string'
import styled from 'styled-components'

const IFrame = styled.iframe`
  width: 100%;
  height: 515px;
  border: none;
`

function Video({ youtube_link }) {
  const { query } = queryString.parseUrl(youtube_link)

  return (
    <section className="container mb-5">
      <div className="row justify-content-center">
        <div className="col-lg-9">
          <IFrame
            src={`https://www.youtube.com/embed/${query.v}`}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </div>
    </section>
  )
}

export default Video
