import React from 'react'

function WindowBig({ imageUrl }) {
  return (
    <svg
      className="w-100"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="415"
      height="606"
      viewBox="0 0 415 606"
    >
      <defs>
        <clipPath id="a">
          <path
            fill="#d8d8d8"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="15"
            d="M0 591V199.928C0 89.51 89.543 0 200 0s200 89.51 200 199.928V591z"
          />
        </clipPath>
      </defs>
      <path
        fill="#d8d8d8"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="15"
        d="M0 591V199.928C0 89.51 89.543 0 200 0s200 89.51 200 199.928V591z"
        transform="translate(7.5 7.5)"
      />
      <g clipPath="url(#a)" transform="translate(7.5 7.5)">
        <image
          width="484"
          height="626"
          transform="translate(-40 -35)"
          xlinkHref={imageUrl}
        />
      </g>
    </svg>
  )
}

export default WindowBig
