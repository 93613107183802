import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.color.face[props.faceTheme]};
  display: inline-block;
  min-width: 260px;
  text-align: center;

  & > a,
  & > button {
    font-family: ${(props) => props.theme.font.family.secondaryLight};
    font-size: ${(props) => props.theme.font.size.xm};
    color: ${(props) => props.theme.color.text[props.faceTheme]};
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: block;
  }
`

const ButtonInverse = ({
  isAnchor,
  isCustom,
  to,
  children,
  className,
  theme = 'light',
}) => (
  <StyledButton faceTheme={theme} className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonInverse
