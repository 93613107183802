import React from 'react'
import styled from 'styled-components'

import ParseContent from 'components/shared/ParseContent'
import ButtonInverse from 'components/elements/ButtonInverse'
import WindowBig from 'components/mask/WindowBig'

const WindowContainer = styled.div`
  @media (max-width: 767px) {
    width: 50% !important;
    margin: 0 auto;
    height: 500px;
  }
`

function Banner({ description, description_under_banner, image, link }) {
  return (
    <section className="mb-5">
      <div className="color-background-main color-light">
        <div className="container pt-5 pb-5 pb-lg-0">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-5 my-5">
              <ParseContent className="mb-5" content={description} />

              {link && (
                <ButtonInverse inAnchor to={link.url}>
                  {link.title}
                </ButtonInverse>
              )}
            </div>

            <WindowContainer className="col-md-6 mb-n5 mt-n5 d-flex align-items-center">
              <WindowBig
                imageUrl={
                  image && image.localFile
                    ? image.localFile.publicURL
                    : image.url
                }
              />
            </WindowContainer>
          </div>
        </div>
      </div>

      {description_under_banner && (
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6">
              <ParseContent content={description_under_banner} />
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default Banner
