import React from 'react'
import styled from 'styled-components'

import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

const Content = styled(ParseContent)`
  h2 {
    font-family: ${({ theme }) => theme.font.family.secondaryThin};

    strong {
      font-family: ${({ theme }) => theme.font.family.tertiary};
      color: ${({ theme }) => theme.color.face.main};
    }
  }
`

function ColumnWithImageLeft({ image, description }) {
  return (
    <section className="container mb-5 pt-5">
      <div className="row justify-content-center">
        <div className="col-lg-4">
          <Image src={image} />
        </div>

        <div className="offset-lg-1 col-lg-7 pt-5">
          <Content content={description} />
        </div>
      </div>
    </section>
  )
}

export default ColumnWithImageLeft
