import React from 'react'
import ParseContent from 'components/shared/ParseContent'

function Column({ editor }) {
  return (
    <section className="container mb-5">
      <div className="row">
        <div className="col-lg-12">
          <ParseContent content={editor} />
        </div>
      </div>
    </section>
  )
}

export default Column
